* {
  font-family: "Poppins";
}
span {
  font-family: "Poppins" !important;
  font-size: 16px !important;
}
p {
  margin: 0;
  font-size: 16px;
}
